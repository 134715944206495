import {stringify} from 'query-string';
import {tokenKey} from 'src/services/storage';
import isServer from 'src/utils/isServer';

const handleErrors = async (response: any) => {
  if (!response.ok) {
    // Error catchers in form submissions are expecting response to read error messages from
    return Promise.reject(response);
  }

  return response;
};

export type ClientRequestOptions = {
  url: string;
  options?: RequestInit;
  query?: Object;
  body?: any;
  disableRedirect?: boolean;
  authCookie?: any;
  skipParsing?: boolean;
};

export const clientRequest = ({
  url,
  options,
  query,
  body,
  disableRedirect,
  authCookie,
  skipParsing
}: ClientRequestOptions) => {
  const finalOptions = {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      cookie: authCookie ? `${tokenKey}=${authCookie}` : undefined
    },
    body: body ? JSON.stringify(body) : undefined
  };

  const apiUrl = isServer ? process.env.NEXT_PUBLIC_PRODUCTION_APP_URL : '';

  let completeUrl = `${apiUrl}/api${url}`;

  if (query) {
    completeUrl += `?${stringify(query)}`;
  }

  return fetch(completeUrl, finalOptions)
    .then((res) => handleErrors(res))
    .catch((res) => {
      if (res.status === 403 && !disableRedirect) {
        // Do not throw error in case of expected unauthorised request
      }

      return Promise.reject(res);
    })
    .then(async (res) => {
      if (skipParsing) return res;

      const responseText = await res.text();

      try {
        const data = JSON.parse(responseText);

        return data;
      } catch (error) {
        return responseText;
      }
    });
};

export const ssrRequest = (req: any, fetcherOptions: ClientRequestOptions) =>
  clientRequest({...fetcherOptions, authCookie: req?.cookies[tokenKey]});
